import styled from 'styled-components';

export const Container = styled.div`
    width: 1200px;
    display: flex;
        justify-content: center;
    ${(p)=>p.padding100 && `
      padding: 100px 0;
      @media (max-width: 768px) {
        padding: 30px 0;
    }
    `}
    ${(p)=>p.flexcolumn && `
      flex-direction: column;
    `}
    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
    }
`;