import React from 'react'
import { Root, Container } from './StatsStyle.js';
import StatsDetail from './StatsDetail.js';

const Stats = ({ aboutRef }) => {
  return (
    <>
      <Root ref={aboutRef}>
        <Container>
          <StatsDetail content="FNO" />
          <StatsDetail content="MCX" />
          <StatsDetail content="FX" />
          <StatsDetail content="CRYPTO" />
          <StatsDetail content="US" />
        </Container>
      </Root>
    </>
  )
}

export default Stats