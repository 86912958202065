import React from 'react'
import {Root, Box, BoxHead, BoxSubHead, BoxTopIconMain } from './CardStyle'
import { BoxTopIcon } from './CardStyle.js'


const Card = ({Heading, SubHeading,BoxIcon}) => {
  return (
    <>
    <Root>
        <Box>
            <BoxHead>{Heading}</BoxHead>
            <BoxSubHead>{SubHeading}</BoxSubHead>
            <BoxTopIconMain>
                <BoxTopIcon src= {BoxIcon}></BoxTopIcon>
            </BoxTopIconMain>
        </Box>
    </Root>
    </>
  )
}

export default Card