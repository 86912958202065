import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    padding: 100px 0;
    @media (max-width: 768px) {
        padding: 30px 0;
    }
`;
export const Container = styled.div`
    width: 1200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const NewsMain = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
       padding: 0 30px;
    }
`;
export const Header = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px; 
`;
export const Para = styled.div`
    color: #B1B1B1;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    width: 600px;
    padding: 20px 0;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0 0 10px;
    }
`;
export const NewsAll = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
