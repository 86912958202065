import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #131313;
    padding: 100px 0;
    @media (max-width: 768px) {
        padding: 30px 0;
    }
`;
export const Container = styled.div`
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const HowWorksMain = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding: 0 30px;
    }
`;
export const Heading = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
`;
export const SubHeading = styled.div`
    color: #B1B1B1;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding: 20px 0 30px;
    width: 600px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const HowCards = styled.div`
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;