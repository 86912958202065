import React from 'react'
import styled from 'styled-components';


const Root = styled.div``;
const Button = styled.div`
    border-radius: 10px;
    background: #02BB6B;
    padding: 15px 40px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    /* width: 160px; */
`;

const GreenButton = () => {
  return (
    <>
      <Root>
        <Button>Get Started Download APP</Button>
      </Root>
    </>
  )
}

export default GreenButton