import React from 'react'
import {Container} from '../CommonStyle.js';
import {Root,AboutMain, Cards, Heading1, Para, TopSection } from './AboutStyle.js';
import Card from '../Card/Card.js';
import BoxIcon from '../../Assets/Images/cost_effective_icon.png'
import BoxIcon2 from '../../Assets/Images/cost_effective_icon2.png'
import BoxIcon3 from '../../Assets/Images/cost_effective_icon3.png'

const About = ({serviceRef}) => {
  return (
    <>
    <Root ref={serviceRef}>
      <Container padding100 flexcolumn>
        <AboutMain>
          <TopSection>
              <Heading1>Uniting Markets, Maximizing Opportunities</Heading1>
              <Para>The Tradeyaar aids in analyzing markets, executing trades, managing risk, and optimizing strategies.</Para>
          </TopSection>
          <Cards>
              <Card Heading='MCX Trading' BoxIcon={BoxIcon} SubHeading='Diversify your portfolio with commodity derivatives.'/>
              <Card Heading='NFO Trading' BoxIcon={BoxIcon2} SubHeading='Explore index futures, stock futures, index options, and stock options with ease.'/>
              <Card Heading='Forex Trading' BoxIcon={BoxIcon3} SubHeading='Allows you to trade major currency pairs and unlock global opportunities.'/>
          </Cards>
        </AboutMain>
      </Container>
    </Root>
    </>
  )
}

export default About