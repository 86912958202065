import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #131313;
    padding: 100px 0;
    @media (max-width: 768px) {
        padding: 30px 0;
    }
`;
export const Container = styled.div`
    width: 1200px;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
    }
`;
export const TraderMain = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        padding: 0 30px;
    }
`;
export const LeftSide = styled.div`
    text-align: left;
`;
export const Heading = styled.div`
    color: #FFF;
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
    padding-bottom: 20px;
    @media (max-width: 768px) {
        font-size: 36px;
        padding-bottom: 5px;
    }
    
`;
export const SubHeading = styled.div`
    color: #B1B1B1;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 20px;
`;
export const AppIcons = styled.div`
    padding-bottom: 20px;
    @media (max-width: 768px) {
        display: flex;
    }
`;
export const Scanner = styled.div`
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: #000;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;
export const Scan = styled.div`
    border-radius: 10px;
    background: #131313;
    width: 118px;
    height: 118px; 
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const QrCode = styled.img`
     
`;
export const ScanText = styled.div`

`;
export const ScanHeadText = styled.div`
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
`;
export const ScanSubHeadText = styled.div`
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
`;
export const RightSide = styled.div`
    
`;
export const RightSideImg = styled.img`
    @media (max-width: 768px) {
        display: none;
    }
`;
export const AppleImage = styled.img`
    @media (max-width: 768px) {
     width: 50%;
    }
    cursor: pointer;
`;
export const GoogleImage = styled.img`
    @media (max-width: 768px) {
     width: 50%;
    }
    padding-left: 10px;
    cursor: pointer;
`;