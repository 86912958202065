import './App.css';
import Landing_Page from './pages/Landing_Page';
import { useRef } from 'react';

function App() {

  const aboutRef = useRef(null)
  const serviceRef = useRef(null)
  const tradingRef = useRef(null)
  const newsRef = useRef(null)
  const contactRef = useRef(null)
  return (
    <div className="App">
      <Landing_Page aboutRef ={aboutRef} serviceRef ={serviceRef} tradingRef ={tradingRef} newsRef ={newsRef} contactRef ={contactRef} />
    </div>
  );
}

export default App;
