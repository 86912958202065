import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    padding: 0px 10px;
    @media (max-width: 768px) {
        padding: 0;
        margin-bottom: 10px;
    }
`;
export const NewsContainer = styled.div`
    padding: 30px 20px;
    background-color: #131313;
    border: 1px solid #ffffff26;
    border-radius: 10px;
    text-align: left;
`;
export const NewsImg = styled.img`
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const NameDate = styled.div`
    color: #B1B1B1;
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
`;
export const NewsHeading = styled.div`
    color: #FFF;
    font-size: 30px;
    text-transform: capitalize;
    padding: 20px 0;
    @media (max-width: 768px) {
        font-size: 18px;
        padding: 10px 0;
    }
`;
export const ReadMore = styled.div`
    color: #02BB6B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding: 20px 0;
    cursor: pointer;
    @media (max-width: 768px) {
        padding: 0;
    }
`;
