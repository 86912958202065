import React from 'react'
import {Root, CardBox, MainIcon, IconTextHead, IconTextSubHead, HowIcon } from './HowCardDetailStyle'


const HowCardDetail = ({HowWorkIcon, Heading, SubHeading}) => {
  return (
    <>
    <Root>
        <CardBox>
            <MainIcon>
                <HowIcon src={HowWorkIcon}></HowIcon>
            </MainIcon>
            <IconTextHead>{Heading}</IconTextHead>
            <IconTextSubHead>{SubHeading}</IconTextSubHead>
        </CardBox>
    </Root>
    </>
  )
}

export default HowCardDetail