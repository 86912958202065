import React from 'react'
import { Root, Buttons, Heading, LeftContent, Para, RightContent, RightContentImg, SpecialCards } from './SpecialCardStyle'
import { Container } from '../CommonStyle.js';
import RightImg from '../../Assets/Images/are_you_ready.png'
import { Button } from '../Navigation/NavigationStyle'

const SpecialCard = () => {
  return (
    <>
      <Root>
        <Container>
          <SpecialCards>
            <LeftContent>
              <Heading>Stay Updated with Economic Calendars</Heading>
              <Para>A schedule of upcoming economic events, announcements, and reports that may impact financial markets.</Para>
              <Buttons>
                {/* <Button>Sign In</Button> */}
                <Button
                  onClick={() => { window.location.href = "https://tradeyarr.com/tradeyarr.apk" }}

                  style={{
                    // width: "500px",
                    padding: "15px 40px",
                    fontSize: "16px",
                    color: "#fff",
                    fontWeight: "500",
                    backgroundColor: "#02BB6B",
                    borderWidth: "0px",
                    outline: "none",
                    borderRadius: "8px",
                    cursor: "pointer"
                  }}

                >Download APK</Button>

              </Buttons>
            </LeftContent>
            <RightContent>
              <RightContentImg src={RightImg}></RightContentImg>
            </RightContent>
          </SpecialCards>
        </Container>
      </Root>
    </>
  )
}

export default SpecialCard