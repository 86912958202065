import React from 'react'
import {Root,MainServiceMain, LeftContainer, RightContainer, Heading, SubHeading, TextWithIcon, IconMain, IconText, IconTextHead, IconTextSubHead,IconImg,MainServiceImg} from './MainServiceStyle.js';
import {Container} from '../CommonStyle.js';
import IconImage from '../../Assets/Images/cost_effective_icon.png';
import MainServiceImage from '../../Assets/Images/MainServiceImage.png';

const MainService = () => {
  return (
    <>
    <Root>
      <Container padding100>
        <MainServiceMain>
          <LeftContainer>
              <MainServiceImg src={MainServiceImage}></MainServiceImg>
          </LeftContainer>
          <RightContainer>
              <Heading>Kickstart the Forex market with confidence.</Heading>
              <SubHeading>Achieve consistent profits with the best Forex signal provider with timely market insights to boost your trading performance.</SubHeading>
              <TextWithIcon>
                  <IconMain>
                      <IconImg src={IconImage}></IconImg>
                  </IconMain>
                  <IconText>
                      <IconTextHead>Mission</IconTextHead>
                      <IconTextSubHead>Our mission is to provide a reliable, transparent, and feature-rich platform that simplifies trading and maximizes your profits.</IconTextSubHead>
                  </IconText>
              </TextWithIcon>
          </RightContainer>
        </MainServiceMain>
      </Container>
    </Root>
    </>
  )
}

export default MainService