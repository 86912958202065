import React from 'react'
import { Root, Heading, SubHeading, Subscribe, HeroBannerMain, DownloadButton } from './HeroBannerStyle.js';
import { Container } from '../CommonStyle.js';
import GreenButton from '../GreenButton.js'


const HeroBanner = () => {
  return (
    <>
      <Root>
        <Container>
          <HeroBannerMain>
            <Heading>Tradeyarr: Your Gateway to Global Trading Excellence</Heading>
            <SubHeading>Discover the perfect blend of strategy and opportunity with TradeYarr and ensure your trades align with your financial goals.</SubHeading>
            {/* <Subscribe>Enter email address <GreenButton/> </Subscribe> */}
            <DownloadButton />

            <button
              onClick={() => { window.location.href = "https://tradeyarr.com/tradeyarr.apk" }}
              style={{
                // width: "500px",
                padding: "15px 40px",
                fontSize: "16px",
                fontWeight: "600",
                color: "#fff",
                fontWeight: "500",
                backgroundColor: "#02BB6B",
                borderWidth: "0px",
                outline: "none",
                borderRadius: "8px",
                cursor: "pointer",
                marginTop: "15px"
              }}
            >OR SIGNUP</button>

          </HeroBannerMain>
        </Container>
      </Root>
    </>
  )
}

export default HeroBanner