import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    padding: 0 0 100px;
    @media (max-width: 768px) {
        padding: 0 0 30px;
    }
`;
export const Container = styled.div`
    width: 1200px;
    border-radius: 10px;
    background-color: #131313;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    @media (max-width: 768px) {
        width: 100%;
        padding: 50px 0;
    }
`;
export const Heading = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
    text-transform: capitalize;
    width: 600px;
    @media (max-width: 768px) {
        width: 100%;
        font-size: 36px;
    }
`;
export const EmailInput = styled.div`
    color: #8d8d8d80;
    font-size: 16px;
    font-weight: 400;
    border-radius: 10px;
    padding: 5px 5px 5px 20px;
    background-color: #ffffff1a;
    width: 500px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
        width: 90%;
    }
`;
