import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
`;

export const Container = styled.div`
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
    }
`;
export const AboutMain = styled.div`
    
    @media (max-width: 768px) {
       padding: 0 30px;
    }
`;
export const TopSection = styled.div`
    display: flex;
    @media (max-width: 768px) {
        
        flex-direction: column;
    }
`;
export const Heading1 = styled.div`
    color: #FFF;
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    width: 90%;
    @media (max-width: 768px) {
        font-size: 36px;
    }
`;
export const Para = styled.div`
    color: #B1B1B1;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    width: 100%;
    padding-top: 20px;
`;
export const Cards = styled.div`
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;