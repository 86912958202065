import React from 'react'
import {FootLogo,FooterMain, SocialImg, FootTitle, FootSocial, FootText, Info, Links, Root } from './FooterStyle'
import {Container} from '../CommonStyle.js';
import FooterImg from '../../Assets/Images/footer_logo.png'
import FacebookImg from '../../Assets/Images/facebook_icon.png'
import FacebookImg2 from '../../Assets/Images/facebook_icon2.png'
import FacebookImg3 from '../../Assets/Images/facebook_icon3.png'
import FacebookImg4 from '../../Assets/Images/facebook_icon4.png'

const Footer = () => {
  return (
    <>
        <Root>
            <Container>
                <FooterMain>
                    <Info>
                        <FootLogo src={FooterImg}></FootLogo>
                        <FootText>We bring together the world of MCX, NFO, and Forex options in one comprehensive platform.</FootText>
                        <FootSocial>
                            <ul>
                                <li> <SocialImg src={FacebookImg}></SocialImg></li>
                                <li> <SocialImg src={FacebookImg2}></SocialImg></li>
                                <li> <SocialImg src={FacebookImg3}></SocialImg></li>
                                <li> <SocialImg src={FacebookImg4}></SocialImg></li>
                            </ul>
                        </FootSocial>
                    </Info>
                    <Links>
                            <FootTitle>Quick Links</FootTitle>
                        <ul>
                            <li>Home</li>
                            <li>Who We Are</li>
                            <li>Services</li>
                            <li>Tranding</li>
                            <li>Contact</li>
                        </ul>
                    </Links>
                    <Links>
                            <FootTitle>Support</FootTitle>
                        <ul>
                            <li>Support Career</li>
                            <li>24/7 </li>
                        </ul>
                    </Links>
                    <Links>
                            <FootTitle>Contact</FootTitle>
                        <ul>
                            <li>WhatsApp</li>
                            <li>Telegram</li>
                        </ul>
                    </Links>
                </FooterMain>
            </Container>
        </Root>
    </>
  )
}

export default Footer