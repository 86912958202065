import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #131313;
`;
export const CardBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
`;
export const MainIcon = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 30px;
    background: #252525;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const HowIcon = styled.img`
    
`;
export const IconTextHead = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    padding-top: 30px;
    @media (max-width: 768px) {
        padding-top: 10px;
    }
`;
export const IconTextSubHead = styled.div`
    color: #B1B1B1;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-top: 10px;
    @media (max-width: 768px) {
        padding-bottom: 20px;
    }
`;