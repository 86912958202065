import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    padding: 100px 0;
    @media (max-width: 768px) {
       padding: 30px 0;
    }
`;
export const Container = styled.div`
    width: 1200px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const ToolsMain = styled.div`
    
    @media (max-width: 768px) {
        padding: 0 30px;
    }
`;
export const Top = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const Bottom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
export const Heading = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
`;
export const Para = styled.div`
    color: #B1B1B1;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    width: 800px;
    padding: 20px 0 70px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const LeftSide = styled.div`

`;
export const LeftSideImg = styled.img`
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const RightSide = styled.div`
    padding-left: 50px;
    @media (max-width: 768px) {
        padding-left: 0;
    }
`;
export const RightSide1 = styled.div`
    display: flex;
`;
export const Number = styled.div`
    min-width: 40px;
    height: 40px;
    background-color: #02BB6B;
    color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const Content = styled.div`
    padding-bottom: 30px;
    margin: 0 30px 30px;
    border-bottom: 1px solid #222;
    @media (max-width: 768px) {
        margin: 0 10px 20px;
    padding-bottom: 20px;
    }
`;
export const ContentHead = styled.div`
    color: #FFF;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 768px) {
        font-size: 24px;
    }
`;
export const ContentSubHead = styled.div`
    color: #B1B1B1;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;