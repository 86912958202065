import React from 'react'
import Navigation from '../components/Navigation/Navigation.js'
import HeroBanner from '../components/HeroSection/HeroBanner.js'
import Stats from '../components/Stats/Stats.js'
import MainService from '../components/MainService/MainService.js'
import About from '../components/About/About.js'
import SpecialCard from '../components/SpecialCard/SpecialCard.js'
import HowWorks from '../components/HowWorks/HowWorks.js'
import Tools from '../components/ToolsSection/Tools.js'
import Trader from '../components/TraderSection/Trader.js'
import News from '../components/News/News.js'
import Newsletter from '../components/Newsletter/Newsletter.js'
import Footer from '../components/Footer/Footer.js'

function Landing_Page({aboutRef, serviceRef, tradingRef, newsRef, contactRef}) {
  return (
    <>
      <Navigation aboutRef = {aboutRef} serviceRef = {serviceRef} tradingRef = {tradingRef} newsRef = {newsRef} contactRef = {contactRef}/>
      <HeroBanner/>
      <Stats aboutRef = {aboutRef}/>
      <MainService/>
      <About serviceRef = {serviceRef}/>
      <SpecialCard/>
      <HowWorks/>
      <Tools tradingRef = {tradingRef}/>
      <Trader/>
      <News newsRef = {newsRef}/>
      <Newsletter contactRef = {contactRef}/>
      <Footer/>
    </>
  )
}

export default Landing_Page