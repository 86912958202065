import React from 'react'
import { NameDate, NewsContainer, NewsHeading, NewsImg, ReadMore, Root } from './NewsBoxStyle'
import NewsImg1 from '../../../Assets/Images/newsImage1.png'

const NewsBox = () => {
  return (
    <>
        <Root>
            <NewsContainer>
                <NewsImg src={NewsImg1}></NewsImg>
                <NameDate>John Carter   |    July 18, 2023</NameDate>
                <NewsHeading>Is a long established fact that a reader will be distracted by the be distracted by the readable </NewsHeading>
                <ReadMore>Read More...</ReadMore>
            </NewsContainer>
        </Root>
    </>
  )
}

export default NewsBox