import styled from 'styled-components';
import SliderBanner from '../../Assets/Images/Slider_banner.jpg'

export const Root = styled.div`
    width: 100%;
    background-color: #131313;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    @media (max-width: 768px) {
        padding: 30px 0;
    }
`;

export const FooterMain = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0 30px;
    }
`
export const Info = styled.div`
    width: 35%;
    padding-right: 5%;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }
`
export const FootLogo = styled.img`

`
export const FootText = styled.div`
    color: #B1B1B1;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 768px) {
                line-height: 20px;
            }
`
export const FootSocial = styled.div`
    ul{
        list-style: none;
        display: flex;
        margin: 0;
        padding: 20px 0;
        li{
            padding: 10px;
            background-color: #2a2a2a;
            color: #fff;
            margin-right: 10px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            & :hover{
                background-color: #02BB6B;
            }
        }
    }
`
export const SocialImg = styled.img`
`;

export const Links = styled.div`
width: 20%;
    @media (max-width: 768px) {
            width: 100%;
            padding-bottom: 20px;
        }
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            text-decoration: none;
            color: #B1B1B1;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            cursor: pointer;
            ${(p)=>p.title && `
              font-size: 20px;
            `}
            @media (max-width: 768px) {
                line-height: 30px;
            }
        }
    }
`
export const FootTitle = styled.div`
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
`

