import React from 'react'
import MainToolsImage from '../../Assets/Images/ToolImg.png' 
import { Bottom, Container,ToolsMain, Content, ContentHead, ContentSubHead, Heading, LeftSide, LeftSideImg, Number, Para, RightSide, RightSide1, Root, Top } from './ToolsStyle'

const Tools = ({tradingRef}) => {
  return (
    <>
    <Root ref={tradingRef}>
        <Container>
            <ToolsMain>
                <Top>
                    <Heading>Experience the Power of Tradeyaar</Heading>
                    <Para>We employ robust encryption and strict regulatory guidelines to ensure your trading experience is worry-free.</Para>
                </Top>
                <Bottom>
                    <LeftSide>
                        <LeftSideImg src={MainToolsImage}></LeftSideImg>
                    </LeftSide>
                    <RightSide>
                        <RightSide1>
                            <Number>1</Number>
                            <Content>
                                <ContentHead>Charting Software</ContentHead>
                                <ContentSubHead>Spot market trends and price movements to analyze potential entry/exit points</ContentSubHead>
                            </Content>
                        </RightSide1>    
                        <RightSide1>
                            <Number>2</Number>
                            <Content>
                                <ContentHead>Transparent </ContentHead>
                                <ContentSubHead>With our minimum brokerage fees, we maximize returns while minimizing costs.</ContentSubHead>
                            </Content>
                        </RightSide1>    
                        <RightSide1>
                            <Number>3</Number>
                            <Content>
                                <ContentHead>Advanced Dashboard</ContentHead>
                                <ContentSubHead>Stay organized with price alerts, track stocks, order books, and 24/7 support.</ContentSubHead>
                            </Content>
                        </RightSide1>    
                        <RightSide1>
                            <Number>4</Number>
                            <Content>
                                <ContentHead>Daily payout</ContentHead>
                                <ContentSubHead>From a trading account to a registered bank account. No hidden charges. </ContentSubHead>
                            </Content>
                        </RightSide1>  
                    </RightSide>
                </Bottom>
            </ToolsMain>
        </Container>
    </Root>
    </>
  )
}

export default Tools