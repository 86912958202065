import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #202020;
    margin: 50px 0;
    ${(p)=>p.bordernone && `
    border-right:none;
  `};
    @media (max-width: 768px) {
        border-bottom: 1px solid #202020;
        padding-bottom: 30px;
        margin: 10px 0;
        border-right: 0;
    }
`;

export const Container = styled.div`
    
`;

export const Heading = styled.div`
    color: #FFF;
    text-align: left;
    font-size: 32px;
    font-weight: 600;
`;
export const SubHeading = styled.div`
    color: #B1B1B1;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding-top: 10px;
`;

