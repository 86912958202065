import React from 'react'
import { Root, Container, HowWorksMain, Heading, HowCards, SubHeading } from './HowWorksStyle'
import HowCardDetail from './HowCardDetail/HowCardDetail'
import HowWorkIcon from '../../Assets/Images/how_icon.png'
import HowWorkIcon2 from '../../Assets/Images/how_icon2.png'
import HowWorkIcon3 from '../../Assets/Images/how_icon3.png'

const HowWorks = () => {
  return (
    <>
      <Root>
        <Container>
          <HowWorksMain>
            <Heading>Ready to Trade Smarter? </Heading>
            <SubHeading>With our web-based platform and mobile app, you can trade no matter where you are. See how it works!</SubHeading>
            <HowCards>
              <HowCardDetail Heading='Download app and signup' SubHeading='It only takes a few moments to download app and get started.' HowWorkIcon={HowWorkIcon} />
              <HowCardDetail Heading='Fund Your Account' SubHeading='Choose from a variety of secure and convenient payment options.' HowWorkIcon={HowWorkIcon2} />
              <HowCardDetail Heading='Start Trading' SubHeading='Explore the markets and seize profitable opportunities.' HowWorkIcon={HowWorkIcon3} />
            </HowCards>
          </HowWorksMain>
        </Container>
      </Root>
    </>
  )
}

export default HowWorks