import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
`;
export const Container = styled.div`
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const SpecialCards = styled.div`
    background-color: #e6f9f121;
    border-radius: 30px;
    padding: 0 70px;
    margin-bottom: 100px;
    width: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        padding: 0;
    }
`;
export const LeftContent = styled.div`
    @media (max-width: 768px) {
        padding: 30px;
    }
`;
export const Heading = styled.div`
    color: #FFF;
    font-size: 40px;
    font-weight: 600;
    text-align: left;
`;
export const Para = styled.div`
    color: #B1B1B1;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
`;
export const Buttons = styled.div`
    display: flex;
    padding-top: 20px;
`;
export const RightContent = styled.div`
    
`;
export const RightContentImg = styled.img`
    @media (max-width: 768px) {
        display: none;
    }
`;