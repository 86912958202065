import styled from 'styled-components';

export const Root = styled.div`
    position: absolute;
    top: 0;
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: #00000014;
    color: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #ffffff24;
    position: absolute;
    top: 0;
    @media (max-width:768px){
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        background-color: #00000014;
        color: #fff;
        padding: 10px 5%;
        border-bottom: 1px solid #ffffff24;
        position: absolute;
        top: 0;
    }
    
`

export const LeftNav = styled.div``

export const Logo = styled.img``

export const RightNav = styled.div`
    display: flex;
    align-items: center;
    @media (max-width:768px){
        display: none;
    }
`

export const Tabs = styled.div`
    ul{
        display: flex;
        list-style: none;
        li{
            padding: 0 40px;
            margin: 0;
            cursor: pointer;
            transition: 0.5s;
            &:hover{
                color: #02BB6B;
            }
        }
    }
`

export const NavButtons = styled.div`
     display: flex;
     padding-left: 20px;
`

export const Button = styled.div`
    border-radius: 10px;
    background: #02BB6B;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 40px;
    margin: 0 5px;
    cursor: pointer;
    ${(p) => p.SignUp && `
      background: #000;
      border: 1px solid rgba(255, 255, 255, 0.30);
    `}
    ${(p) => p.SignUp && `
      background: #000;
      border: 1px solid rgba(255, 255, 255, 0.30);
    `}
`

export const MobileViewAppBar = styled.div`
    display: none;
    background-color: #f1f7f5;
    color: #000;
    border-radius: 50px;
    padding: 10px 30px;
    position: relative;
    z-index: 999;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 767px) {
        display:flex ;
    }
`;


export const MobileMenu = styled.div`
    background-color: #353535;
    position: fixed;
    top: 0;
    transform:${(p) => p.ScrollBar ? 'translateX(0%);' : 'translateX(100%)'} ;
    right: 0;
    height: 100vh;
    width: 80%;
    padding-top: 60px;
    transition: 1s;
    z-index: 100;
    ul{
        padding: 0;
        li{
            color: #fff;
            list-style: none;
            text-align: left;
            font-size: 18px;
            line-height: 70px;
            border-bottom: 1px solid #3d3c3c;
            padding: 0 30px;
        }
    }
`

export const DownloadButton = () => <>
    <Button
        onClick={() => { window.location.href = "https://tradeyarr.com/tradeyarr.apk" }}

        style={{
            // width: "500px",
            padding: "15px 40px",
            fontSize: "16px",
            color: "#fff",
            fontWeight: "500",
            backgroundColor: "#02BB6B",
            borderWidth: "0px",
            outline: "none",
            borderRadius: "8px",
            cursor: "pointer"
        }}

    >Download APK</Button>
</>

export const WhatsappButton = () => <>
    <a href="https://api.whatsapp.com/send?phone=917696128316&text=Hi%2C%20I%20would%20like%20to%20create%20an%20account%20on%20TradeYarr" target="_blank">
        <img src="https://tradex.live/images/whatsappp.png" style={{
            bottom: "100px",
            height: "92px",
            position: "fixed",
            right: "11px",
            zIndex: "99"
        }} />
    </a>
</>