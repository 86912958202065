import React from 'react'
import { Container,NewsMain, Header, NewsAll, Para, Root } from './NewsStyle'
import NewsBox from './NewsBox/NewsBox'

const News = ({newsRef}) => {
  return (
    <>
       <Root ref={newsRef}>
            <Container>
              <NewsMain>
                  <Header>Our Latest News</Header>
                  <Para>Enim lobortis scelerisque fermentum dui faucibus. Dolor purus non enim praesent elementum facilisis leo vel fringilla. Nulla facilisi etiam dignissim diam. Ultrices gravida dictum fusce ut placerat.</Para>
                  <NewsAll>
                      <NewsBox/>
                      <NewsBox/>
                  </NewsAll>
                </NewsMain>
            </Container  >
        </Root> 
    </>
  )
}

export default News