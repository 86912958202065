import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    padding: 0 10px;
    margin: 100px 0;
    @media (max-width: 768px) {
        padding: 0;
        margin: 70px 0 0;
    }
`;

export const Box = styled.div`
    border-radius: 30px;
    border: 1px solid rgba(249, 250, 252, 0.20);
    background: #131313;
    padding: 100px 0px 50px 50px;
    position: relative;
    text-align: left;
`;
export const BoxHead = styled.div`
    color: #FFF;
    font-size: 24px;
    font-weight: 500;
`;
export const BoxSubHead = styled.div`
    color: rgba(177, 177, 177, 0.80);
    font-size: 16px;
    font-weight: 400;
    padding-top: 20px;
`;
export const BoxTopIconMain = styled.div`
    position: absolute;
    background-color: #252525;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    top: -40px;
`;
export const BoxTopIcon = styled.img`

`;