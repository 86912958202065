import React from 'react'
import { WhatsappButton, Root, Container, MobileViewAppBar, MobileMenu, LeftNav, Logo, RightNav, Tabs, NavButtons, Button, DownloadButton } from './NavigationStyle.js';
import LogoImg from '../../Assets/Images/logo.png';
import { useState } from "react";

function Navigation({ aboutRef, serviceRef, tradingRef, newsRef, contactRef }) {
  const [scrollBar, setScrollBar] = useState(false)

  const Handleabout = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' })
    setScrollBar(!scrollBar)
  }
  const Handleservice = () => {
    serviceRef.current.scrollIntoView({ behavior: 'smooth' })
    setScrollBar(!scrollBar)
  }
  const Handletrading = () => {
    tradingRef.current.scrollIntoView({ behavior: 'smooth' })
    setScrollBar(!scrollBar)
  }
  const Handlenews = () => {
    newsRef.current.scrollIntoView({ behavior: 'smooth' })
    setScrollBar(!scrollBar)
  }
  const Handlecontact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' })
    setScrollBar(!scrollBar)
  }

  return (
    <>
      <WhatsappButton />
      <Container>

        <LeftNav>
          <Logo src={LogoImg}></Logo>
        </LeftNav>
        <RightNav>
          <Tabs>
            <ul>
              <li>Home</li>
              <li onClick={() => aboutRef.current.scrollIntoView({ behavior: 'smooth' })}>Who We Are</li>
              <li onClick={() => serviceRef.current.scrollIntoView({ behavior: 'smooth' })}>Services</li>
              <li onClick={() => tradingRef.current.scrollIntoView({ behavior: 'smooth' })}>Trading</li>
              <li onClick={() => newsRef.current.scrollIntoView({ behavior: 'smooth' })}>News</li>
              <li onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth' })}>Contact</li>
            </ul>
          </Tabs>
          <NavButtons>
            {/* <Button>Sign In</Button> */}
            {/* <Button SignUp>Sign Up</Button> */}
            <DownloadButton />
          </NavButtons>
        </RightNav>
        <MobileViewAppBar scrollBar={scrollBar} onClick={() => setScrollBar(!scrollBar)}>{scrollBar ? 'CLOSE' : "MENU"}</MobileViewAppBar>
        <MobileMenu ScrollBar={scrollBar}>
          <ul>
            <li>Home</li>
            <li onClick={Handleabout}>Who We Are</li>
            <li onClick={Handleservice}>Services</li>
            <li onClick={Handletrading}>Trading</li>
            <li onClick={Handlenews}>News</li>
            <li onClick={Handlecontact}>Contact</li>
          </ul>
        </MobileMenu>
      </Container>
    </>
  )
}

export default Navigation