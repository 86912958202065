import styled from 'styled-components';
import SliderBanner from '../../Assets/Images/Slider_banner.jpg'

export const Root = styled.div`
    width: 100%;
    background-color: black;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size:cover;
    background-image: url(https://static.vecteezy.com/system/resources/previews/002/623/307/non_2x/red-and-green-candlestick-chart-with-marked-high-and-low-positions-and-volume-chart-on-black-background-trading-graphic-design-concept-financial-stock-market-cryptocurrency-graph-illustration-vector.jpg);
`;

export const HeroBannerMain = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const Heading = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 58px;
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    @media (max-width: 768px) {
        font-size: 48px;
    }
`
export const SubHeading = styled.div`
    color: #B1B1B1;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 30px 0;
    width: 80%;
    @media (max-width: 768px) {
        margin: 20px 0 30px;
    }
`
export const Subscribe = styled.div`
    border-radius: 10px;
    background-color: #ffffff30;
    width: 500px;
    padding: 5px 5px 5px 20px;
    text-align: left;
    color: #B1B1B1;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
        width: 90%;
    }
`

export const DownloadButton = () => <>
    <button className='herob' style={{
        // width: "500px",
        marginTop: "10px",
        padding: "15px 40px",
        fontSize: "20px",
        color: "#fff",
        fontWeight: "600",
        backgroundColor: "#02BB6B",
        borderWidth: "0px",
        outline: "none",
        borderRadius: "8px",
        cursor: "pointer"
    }} onClick={() => { window.location.href = "https://tradeyarr.com/tradeyarr.apk" }}>Download APK</button>
    <style>
        {`
        @media (min-width: 701px) {
          .herob {
           width:500px;
          }
        }
      `}
        {`
        @media (max-width: 700px) {
          .herob {
           width:300px;
          }
        }
      `}
    </style>
</> 