import React from 'react'
import { Container, EmailInput, Heading, Root } from './NewsletterStyle'
import GreenButton from '../GreenButton'

const Newsletter = ({ contactRef }) => {
  return (
    <>
      <Root ref={contactRef}>
        <Container>
          <Heading>Stay Updated with the Latest Market Trends</Heading>
          {/* <EmailInput>Enter your company name <GreenButton/> </EmailInput> */}
          <GreenButton />
        </Container>
      </Root>
    </>
  )
}

export default Newsletter