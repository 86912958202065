import React from 'react'
import { Root, Container, Heading, SubHeading } from './StatsDetailStyle.js';

const StatsDetail = ({ content }) => {
  return (
    <>
      <Root>
        <Container>
          <Heading>{content}</Heading>
          {/* <SubHeading>24h trading volume on Tradeyaar</SubHeading> */}
        </Container>
      </Root>
    </>
  )
}

export default StatsDetail