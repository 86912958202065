import React from 'react'
import { AppIcons,AppleImage,QrCode, GoogleImage, Container,TraderMain, Heading, LeftSide, RightSide, RightSideImg, Root, Scan, ScanHeadText, ScanSubHeadText, ScanText, Scanner, SubHeading } from './TraderStyle'
import TradeImg from '../../Assets/Images/trader_Img.png'
import Apple_App from '../../Assets/Images/apple_app.png'
import Google_App from '../../Assets/Images/google_app.png'
import ScanImg from '../../Assets/Images/qrcode.png'

const Trader = () => {
  return (
    <>
        <Root>
            <Container>
                <TraderMain>
                    <LeftSide>
                        <Heading>Get the app!</Heading>
                        <SubHeading>Become a Pro trader today. Enjoy the freedom of monitoring stock markets hassle-free on the go.</SubHeading>
                        <AppIcons>
                            <AppleImage src={Apple_App}></AppleImage>
                            <GoogleImage src={Google_App}></GoogleImage>
                        </AppIcons>
                        <Scanner>
                            <Scan>
                                <QrCode src={ScanImg}></QrCode>
                            </Scan>
                            <ScanText>
                                <ScanHeadText>Scan to download</ScanHeadText>
                                <ScanSubHeadText>for ios & android</ScanSubHeadText>
                            </ScanText>
                        </Scanner>
                    </LeftSide>
                    <RightSide>
                        <RightSideImg src={TradeImg}></RightSideImg>
                    </RightSide>
                </TraderMain>
            </Container>
        </Root>
    </>
  )
}

export default Trader