import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #131313;
`;

export const Container = styled.div`
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const MainServiceMain = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
       padding: 0 30px;
       flex-direction: column;
    }
`;

export const LeftContainer = styled.div`
    width: 50%;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 50%;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const Heading = styled.div`
    color: #FFF;
    font-size: 40px;
    line-height: 45px;
    font-weight: 700;
`;
export const SubHeading = styled.div`
    color: #B1B1B1;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    padding: 20px 0;
    text-align: justify;
`;
export const TextWithIcon = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
`;
export const IconMain = styled.div`
    background-color: #252525;
    border-radius: 20px;
    min-width: 85px;
    min-height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const IconImg = styled.img`

`;
export const MainServiceImg = styled.img`
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const IconText = styled.div`
    padding-left: 20px;
    @media (max-width: 768px) {
        padding-left: 0;
    }
`;
export const IconTextHead = styled.div`
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
`;
export const IconTextSubHead = styled.div`
    color: #B1B1B1;
    font-size: 16px;
    font-weight: 400;
`;